<template>
  <v-stepper v-model="step" vertical>
    <v-stepper-step :complete="step > 1" step="1">
      Create a Cognito account
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-card flat>
      <v-card-text>
      <p>Parks on the Air (POTA) uses <a href="https://aws.amazon.com/cognito/" target="_blank">AWS Cognito</a> to manage our account authentication information.
      This provides all parties with a flexible and secure method of signing into POTA.</p>
      <p>Get started by creating a Cognito account using the sign in method of your choice. You can choose to authenticate with your Amazon, Google, or Facebook accounts, or create a unique username and password just for POTA. No matter which method you choose, POTA does not have access to your account or social media data.</p>
      <p>To use Amazon, Google, or Facebook, click the corresponding "Continue with ..." on left side of the Sign In page.</p>
      <p>To use a unique username and password, look for the text "Need an account? Sign up" at the bottom right of the Sign In page. After setting your email and password, you will need to verify your account by clicking the link you receive in your email.</p>
      <p>After creating your account, if you are not automatically redirected back to this page, follow the pop-up prompt on the Spots page to get back to this page and go to the next step.</p>
      <p><b>EMAILS AND PASSWORDS ARE CASE SENSITIVE!</b> Please pay attention to the case your are using for your email, especially if you are signing up on a mobile device which automatically capitalizes the first letter of words.</p>
      </v-card-text>
      </v-card>
      <v-btn color="primary" @click="doSignUp">
        Go to Cognito Sign In
      </v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="step > 2" step="2">
      Complete Sign Up
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-card flat>
        <v-card-text>
            <blockquote class="blockquote"><b class="success--text">You are almost finished!</b> Fill out the following fields and click Submit. For personal accounts use your current personal callsign, and for club accounts, use the clubs primary callsign. Suffixes, old callsigns or special event callsigns, can be connected to your account later. Your full name should be the name you want to appear on your award certificates. Use your real name as anything else may not be approved by the admin team. You can change your display name once your account has been created.</blockquote>
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
                <v-text-field v-model="callsign" label="Current Callsign" @keyup="callsign = callsign.toUpperCase()" :rules="callsignRules" :disabled="submitted" required></v-text-field>
                <v-text-field v-model="name" label="Full Name" :rules="nameRules" :disabled="submitted" required></v-text-field>
            </v-container>
            <v-container grid-list-md>
                <v-checkbox v-model="agree1" :disabled="submitted"><template v-slot:label>I have reviewed the Getting Started guide and am familiar with the POTA documentation website (<a href="https://docs.pota.app" target="_blank">https://docs.pota.app</a>)</template></v-checkbox>
                <v-checkbox v-model="agree2" :disabled="submitted"><template v-slot:label>I have reviewed and agree to the Parks on the Air Rules (<a href="https://docs.pota.app/docs/rules.html" target="_blank">https://docs.pota.app/docs/rules.html</a>)</template></v-checkbox>
                <v-checkbox v-model="agree3" :disabled="submitted"><template v-slot:label>I have reviewed and agree to the Parks on the Air Code of Conduct (<a href="https://docs.pota.app/docs/code_of_conduct.html" target="_blank">https://docs.pota.app/docs/code_of_conduct.html</a>)</template></v-checkbox>
            </v-container>
            <v-container grid-list-md>
                <v-btn color="primary" :disabled="!agreeToAll || submitted" @click="save()">Submit</v-btn>
            </v-container>
            <v-container grid-list-md>
                <blockquote class="blockquote"><b class="font-weight-black green--text">If you already have an account</b> and you are on this page, you may have signed in using a different method. You can sign out and sign in again using your existing method, or you can complete the signup process again to connect your new authentication method to your existing account. All of your stats will remain intact.</blockquote>
            </v-container>
            </v-form>
        </v-card-text>
      </v-card>
    </v-stepper-content>

    <v-stepper-step :complete="step > 3" step="3">
      Wait for approval
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-card flat>
      <v-card-text>
      <p><b class="success--text">What happens next?</b>
        The POTA team will verify your information and either approve your account or email you at the address above with questions.
        This usually happens quickly, but can sometimes take a few days.</p>
      <p>The <router-link to="/">spots page</router-link> will show your account approval status and let you know when your account is approved and active!</p>
      </v-card-text>
      </v-card>
    </v-stepper-content>

    <v-stepper-step step="4">
      Get on the air!
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card flat>
      <v-card-text>
      <p><b class="success--text">Your account is active!</b> Get out there and POTA!</p>
      <p><b>Note:</b> You may need to log out and log back in if you have not already done so.</p>
      </v-card-text>
      </v-card>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import axios from 'axios'
import { Auth } from 'aws-amplify'

export default {
  components: { },
  name: 'SignUp',
  props: {
    show: { type: Boolean, default: false }
  },
  data: function () {
    return {
      step: this.getStartStep(),
      dialog: this.show,
      valid: true,
      callsign: '',
      name: '',
      submitted: false,
      callsignRules: [
        v => !!v || 'Please enter your callsign',
        v => /^[A-Z\d]{1,3}\d[A-Z\d]+$/i.test(v) || 'Example: N0CALL'
      ],
      emailRules: [
        v => !!v || 'Example: user@domain.com',
        v => /^\S+@\S+\.\S+$/.test(v) || 'Please enter a valid email address',
      ],
      nameRules: [
        v => !!v || 'Full name is required',
      ],
      agree1: false,
      agree2: false,
      agree3: false,
    }
  },
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated
      }
    },
    accountStatus: {
      get() {
        return this.$store.state.user.accountStatus
      }
    },
    agreeToAll: {
      get() {
        return this.agree1 && this.agree2 && this.agree3
      }
    }
  },
  watch: {
    accountStatus: function(value) { // eslint-disable-line no-unused-vars
      if (this.isAuthenticated && (this.accountStatus == 'active' || this.accountStatus == 'refresh'))
        this.step = 4
    }
  },
  methods: {
    doSignUp() {
      Auth.federatedSignIn();
    },
    save () {
      if (this.$refs.form.validate()) {
        const formData = {
          callsign: this.callsign,
          name: this.name
        }

        this.submitted = true

        axios
          .post(`https://${process.env.VUE_APP_API_URL}/user/account/create`, formData, this.$store.getters.authTokenHeader)
          .then(() => {
            this.step = 3
            this.$store.dispatch('getAccountStatus')
          })
          .catch(err => {
            this.$dialog.error({ text: `${err.response.data}` })
            this.submitted = false
          })
      }
    },
    close () {
    },
    getStartStep() {
      if (!this.$store.state.user.isAuthenticated)
        return 1

      if (this.$store.state.user.accountStatus == 'active')
        return 4

      if (this.$store.state.user.accountStatus != 'not_found')
        return 3

      return 2
    }
  },
  mounted () {
    
  }
}
</script>

<style scoped>
</style>
